// Scrollbar

.scrollbar-sidebar,
.scrollbar-container {
  position: relative;
  height: 100%;
}

.ps__rail-y {
  z-index: 12;
}

// Scroll Areas

.scroll-area {
  overflow-x: hidden;
  height: 400px;
}

.scroll-area-xs {
  height: 150px;
  overflow-x: hidden;
}

.scroll-area-sm {
  height: 200px;
  overflow-x: hidden;
}

.scroll-area-md {
  height: 300px;
  overflow-x: hidden;
}

.scroll-area-lg {
  height: 400px;
  overflow-x: hidden;
}

.scroll-area-x {
  overflow-x: auto;
  width: 100%;
  max-width: 100%;
}

.shadow-overflow {
  position: relative;

  &::after,
  &::before {
    width: 100%;
    bottom: auto;
    top: 0;
    left: 0;
    height: $layout-spacer-x;
    position: absolute;
    z-index: 13;
    content: '';
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=0);
  }

  &::after {
    bottom: 0;
    top: auto;

    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 80%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
  }
}
