@import "assets/base";

.header-centered {
  text-align: center;
}

.required-height {
  height: 37px !important;
}


.pagination {
  @extend .d-flex, .flex-wrap, .justify-content-center;
}

::ng-deep .p-toast, .p-component, .p-toast-top-right {
  top: 12% !important;
}

//HEADER CLASS FOR FIN/REPORTS/RECONCILIATION
.header_general_data_reconciliation {
  background: rgb(255 189 101);
}

.header_additional_reconciliation {
  background: rgb(181 231 255);
}
